* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  color: #000;
}
